import i1 from '../assets/gallery/22.jpg'
import i2 from '../assets/gallery/pw.jpg'
import i3 from '../assets/gallery/pw2.jpg'
import i4 from '../assets/gallery/Untitled design (1).jpg'
// import i5 from '../assets/gallery/Untitled design (2).jpg'
// import i6 from '../assets/gallery/Untitled design (3).jpg'
import i7 from '../assets/gallery/Untitled design (4).jpg'
import i8 from '../assets/gallery/Untitled design (5).jpg'
import i9 from '../assets/gallery/Untitled design (6).jpg'
// import i5 from '../assets/gallery/Untitled design.jpg'
import i11 from '../assets/gallery/n1.jpg'
import i12 from '../assets/gallery/n2.jpg'
 import i5 from '../assets/gallery/n3.jpg'
import i14 from '../assets/gallery/n4.jpg'
import i15 from '../assets/gallery/n5.jpg'
// import i16 from '../assets/gallery/n7.jpg'
 const data=[{
    id:1,
    img:i2
 },

 
 {
    id:3,
    img:i3
 },
 {
    id:4,
    img:i4
 },
 {
    id:5,
    img:i5
 },
 {
   id:2,
   img:i15
},
 
 {
    id:7,
    img:i7
 },
 {
    id:8,
    img:i8
 },
 {
    id:9,
    img:i9
 },
 {
    id:10,
    img:i14
 },
 {
    id:11,
    img:i11
 },
 {
   id:6,
   img:i1
},
 {
    id:12,
    img:i12
 }
]
export default data;
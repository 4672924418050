import i1 from '../assets/pic/1.jpg'
import i2 from '../assets/pic/2.jpg'
import i3 from '../assets/pic/3.jpg'
import i4 from '../assets/pic/4.jpg'
import i5 from '../assets/pic/5.jpg'
import i6 from '../assets/pic/6.jpg'
import i7 from '../assets/pic/7.jpg'
import i8 from '../assets/pic/8.jpg'
import i9 from '../assets/pic/9.jpg'
import i10 from '../assets/pic/13.jpg'
import i11 from '../assets/pic/11.jpg'
import i12 from '../assets/pic/12.jpg'
//  import i5 from '../assets/gallery/n3.jpg'
// import i14 from '../assets/gallery/n4.jpg'
// import i15 from '../assets/gallery/n5.jpg'
// import i16 from '../assets/gallery/n7.jpg'
 const data=[{
    id:1,
    img:i1
 },

 {
   id:2,
   img:i2
},
 
 {
    id:3,
    img:i3
 },
 {
    id:4,
    img:i4
 },
 {
    id:5,
    img:i5
 },
 
 {
   id:6,
   img:i6
},
 {
    id:7,
    img:i7
 },
 {
    id:8,
    img:i8
 },
 {
    id:9,
    img:i9
 },
 {
    id:10,
    img:i10
 },
 {
    id:11,
    img:i11
 },
 {
    id:12,
    img:i12
 }
]
export default data;